import React, { FunctionComponent, MouseEvent, useContext, useEffect, useState } from "react";
import { Card } from "react-bootstrap";
import AddToCartAlert from "../AddtoCartAlert";
import Button from "../../components/Button";
import { Price } from "../Price";
import { ImageInterface } from "../../types/SanityTypes";
import { LocalizedContext } from "../../services/LocalizedContextService";
import { addProduct } from "../../services/salesforce/Bag";
import SanityImage from "gatsby-plugin-sanity-image";
import EcommerceContext from "../../services/EcommerceContextService";
import { useMutation } from "react-query";
import { productSearchById } from "../../services/salesforce/Product";
import LoaderIcon from "../../images/icons/loader";
import { scAddEvent } from "../../analytics/scAddProduct";
import { IMAGE_SIZES } from "../../constants";

import MikMak from "../MikMak";

import "./styles.scss";

export interface ProductCardInterface {
  index?: number;
  id: string;
  productName: string;
  productIngredients?: string;
  price: number;
  productCategory?: string;
  brandName?: string;
  productLabels: {
    addToBag: string;
    added?: string;
    available?: string;
    soldOut?: string;
  };
  orderable?: boolean;
  image?: ImageInterface;
  showCategory?: boolean;
  isHomeVersion?: boolean;
  setAlertMessage: (alertMessaage: string) => void;
  setShowAlert: (showAlert: boolean) => void;
  setLinkToProduct: (linkToProduct: string) => void;
  productLink: string;
  isInSlider: boolean;
  isOnLandingPage?: boolean;
  isVariation?: boolean;
}

const ProductCard: FunctionComponent<ProductCardInterface> = ({
  id,
  productName,
  productIngredients,
  price,
  productCategory,
  brandName,
  productLabels,
  image,
  orderable,
  showCategory,
  isHomeVersion,
  setAlertMessage,
  setShowAlert,
  setLinkToProduct,
  productLink = "",
  isInSlider = false,
  isOnLandingPage = false,
  isVariation,
  index
}) => {
  const { sanitySiteSettings } = useContext(LocalizedContext);
  const { setBagItems } = useContext(EcommerceContext);

  const [itemOrderable, setItemOrderable] = useState(orderable);
  const [discountedPrice, setDiscountedPrice] = useState(0);
  const [showCardAlert, setShowCardAlert] = useState(false);
  const [alertCardMessage, setAlertCardMessage] = useState("");

  const handleClick = async (event: MouseEvent<HTMLButtonElement, Event>) => {
    event.preventDefault();
    scAddEvent(
      id,
      productName,
      productCategory || "",
      brandName || "",
      price || discountedPrice,
      1,
      "Product Card",
      index
    );
    try {
      const response = await addProduct(id);
      const { quantity, title, basketId } = response?.data;
      if (basketId) {
        setBagItems(response?.data);
        isInSlider ? setAlertMessage("Product Added to the Bag") : setAlertCardMessage("Product Added to the Bag");
      }
      if (quantity && title) {
        isInSlider ? setAlertMessage(title) : setAlertCardMessage(title);
      }
    } catch (err) {
      console.log("error..", err);
      isInSlider
        ? setAlertMessage("Failed to add product to the Bag")
        : setAlertCardMessage("Failed to add product to the Bag");
    } finally {
      isInSlider && setLinkToProduct(productLink);
      isInSlider ? setShowAlert(true) : setShowCardAlert(true);
    }
  };

  const { addToBag, added, available, soldOut } = productLabels;

  const {
    data,
    error,
    isLoading,
    mutate: searchProduct
  } = useMutation({
    mutationFn: productSearchById,
    retry: 1,
    onSuccess: response => {
      const { data = {} } = response || {};
      if (data && data.id) {
        setItemOrderable(data.inventory.orderable);
        const { productPromotions = [] } = data;
        if (productPromotions && productPromotions.length) {
          const promotionalPrice = productPromotions[0].promotionalPrice;
          setDiscountedPrice(promotionalPrice);
        }
      }
    },
    mutationKey: id
  });

  useEffect(() => {
    searchProduct(id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Card
      className={`product-card h-100 ${isOnLandingPage ? "product-card--landing" : ""} ${
        isVariation ? "product-card-variation" : ""
      }`}
    >
      <SanityImage
        width={400}
        height={400}
        htmlWidth={isVariation ? 433 : 400}
        htmlHeight={isVariation ? 533 : 400}
        // crop={isVariation ? { bottom: 0.21, left: 0, right: 0, top: 0 } : {}}
        className="img-fluid"
        sizes={IMAGE_SIZES.CARD}
        asset={image ? image.asset : sanitySiteSettings._rawProductFallbackImage?.asset}
        alt={image ? image.alt : productName}
      />

      <Card.Body className="text-center product-card__content d-flex flex-column">
        {showCategory && !isVariation && <div className="product-card__label">{productCategory}</div>}
        {isVariation && <div className="product-card__ingredients">{productIngredients}</div>}
        <h3 className={`product-card__title ${isVariation ? "product-card__title-variation" : ""}`}>{productName}</h3>
        {isVariation ? (
          <Price isVariation={isVariation} price={price} discountedPrice={discountedPrice} />
        ) : (
          <Price price={price} discountedPrice={discountedPrice} />
        )}

        <MikMak
          brand={sanitySiteSettings.brandName}
          className="product-card__add-to-cart"
          productName={productName}
          productCategoryName={productCategory || ""}
          smartProductId={id}
          variant={isHomeVersion ? "octonary" : "septenary"}
          form="br-50"
          wtbId="661ec2b71033817ba24278fb"
        />
      </Card.Body>
      {showCardAlert && <AddToCartAlert message={alertCardMessage} setShowAlert={setShowCardAlert} />}
    </Card>
  );
};

export default ProductCard;
