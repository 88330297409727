import { EventType, pushEvent } from "./index";

/**
 * Click to purchase / Buy it Now
 */

export const scAddEvent = (
  productId: string,
  productName: string,
  productCategoryName: string,
  brand: string,
  price?: number,
  quantity = 1,
  componentName?: string,
  listPosition = 0
) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const win = window as any;
  win.digitalData.cart = {};
  win.digitalData.cart.item = [];
  win.digitalData.product = [];
  win.digitalData.component = [];
  const productInfo = {
    productInfo: {
      productID: productId,
      productName,
      price,
      brand,
      quantity
    },
    category: {
      primaryCategory: productCategoryName
    },
    pCAT: {
      name: productCategoryName,
      level: listPosition
    },
    attributes: {
      productVariants: "",
      listPosition
    }
  };
  win.digitalData.product.push(productInfo);
  win.digitalData.cart.item.push(productInfo);
  win.digitalData.component.push({
    componentInfo: {
      componentID: componentName
    },
    attributes: {
      position: listPosition,
      listPosition
    }
  });

  pushEvent({
    eventInfo: {
      type: EventType.addtoCart
    },
    category: {
      primaryCategory: EventType.conversion
    },
    subcategory: EventType.lead
  });
};
